import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import RatingsReasonsTooltip from '../shared/ratings-tooltip'
import HeroImage from '../hero-image'
import WatchButton from '../../containers/play-button/showpage-play-button'
import AddOrRemoveFromListButton from '../../containers/add-or-remove-from-list-button'
// TODO: TODO: LBXW-605
// import ShareButton from '../shared/share-button';
import Quote from '../shared/quote'
import styles from './title-detail.css'
import {
  getFirstTrailer,
  getEnableAutoplayTrailer,
  getDetailsFullUrl,
  isKids,
  getTwoGenres,
  maxDescriptionLines
} from '../../lib/utils'
import { getLowestPrice } from '../../lib/content-item'
import { getDurationTextFromMinutes } from '../../lib/date-time'
import UpcomingEpisode from '../shared/upcoming-episode'
import RentalInfo from '../shared/rental-info'
import Reviews from '../shared/reviews'
import CastCrew from '../shared/cast-crew'
import Clamped from '../shared/clamped'
import AutoplayTrailerStream from '../autoplay-trailer/autoplay-trailer-stream'

import CONTENT_ITEM_FRAGMENT from '../../../graphql/fragments/content-item.gql'
import CONTENT_ITEM_LIGHT_FRAGMENT from '../../../graphql/fragments/content-item-light.gql'
import { getLocationBaseWithHref } from '../../lib/location'
import { openGraphMetaImages } from '../../lib/open-graph/images'
import { openGraphMetaActors } from '../../lib/open-graph/actors'
import { openGraphMetaDirectors } from '../../lib/open-graph/directors'
import { openGraphMetaTags } from '../../lib/open-graph/tags'
import { PLAY_BUTTON_IN_TITLE_HERO } from '../../containers/play-button/constants'
import { ViewTrailerButton } from '../../containers/shared/trailers/trailer'
import {
  SCREEN_SIZE_BREAKPOINTS,
  THEME_OPTIONS,
  RENTAL_STATUS
} from '../../constants'
import { RatingDetails } from '../ratingDetails'

import infoIcon from '../../../images/info.svg'
import rentalBadge from '../../../images/neon/rental-badge.png'
import arrowDown from '../../../images/down-arrow.svg'
import arrowUp from '../../../images/up-arrow.svg'
import arrowDownDark from '../../../images/down-arrow-dark.svg'
import arrowUpDark from '../../../images/up-arrow-dark.svg'

import {
  COMPONENT_NAME_TITLE_DETAIL,
  COMPONENT_NAME_TITLE_DETAIL_KIDS
} from '../../lib/analytics/custom-dimensions'
import { HelmetIfNoModal } from '../helmet-if-no-modal'
import { E2E_SELECTORS } from '../../e2e-selectors'
import { LeavingSoonTitle } from '../shared/leaving-soon-title'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'

export const boldRegex = /\*\*\*(.+)\*\*\*/

function TitleDetail({
  contentItem,
  account,
  myList,
  theme,
  rating,
  reason,
  advisories,
  notes,
  rentalStatus,
  screenWidth,
  isExpanded,
  onReadMoreClick
}) {
  const descriptionLines = maxDescriptionLines(screenWidth)
  const showReadMoreButton = screenWidth <= SCREEN_SIZE_BREAKPOINTS.MIN_768
  const upIcon = theme === THEME_OPTIONS.light ? arrowUpDark : arrowUp
  const downIcon = theme === THEME_OPTIONS.light ? arrowDownDark : arrowDown
  const linkIcon = isExpanded ? upIcon : downIcon
  const linkText = isExpanded ? 'READ LESS' : 'READ MORE'

  // as per design show only 2 first genres
  const truncatedGenres = getTwoGenres(contentItem)
  const genres =
    truncatedGenres &&
    truncatedGenres.map(genre => (
      <span
        className={styles.genre}
        key={genre}
        data-lbx-e2e={E2E_SELECTORS.GENRE}
      >
        {genre}
      </span>
    ))
  const year = contentItem.year ? contentItem.year : null
  const duration = contentItem.duration
    ? getDurationTextFromMinutes(contentItem.duration)
    : null
  const quote =
    contentItem.quotes && contentItem.quotes.length > 0
      ? contentItem.quotes[0]
      : null

  const lowestPrice = getLowestPrice(contentItem)

  const imageUri = contentItem.keyart && contentItem.keyart.uri
  const enableAutoplayTrailers = getEnableAutoplayTrailer(contentItem)
  const trailer = getFirstTrailer(enableAutoplayTrailers)
  const isRental = contentItem.isRental
  const isCVODRating = contentItem.rating?.ratingSystem === 'CVOD'

  const displayRentalInfo =
    rentalStatus === RENTAL_STATUS.RENTED && contentItem.rentalInfo != null

  const brand = 'NEON'
  const fromPrice = isRental && lowestPrice ? ` $${lowestPrice}` : ''

  const title = `Watch ${contentItem.title} Online with ${brand}${fromPrice}`

  const isLongTitle = contentItem.title.length > 24

  const displayCVODRating = () => {
    return (
      <RatingDetails
        ratingContent={contentItem.rating}
        reason={contentItem.rating.reason}
      />
    )
  }

  const displayBSVRating = () => {
    return (
      <RatingsReasonsTooltip reason={reason}>
        <span
          className={classNames(styles.rating, styles.titleRating, {
            [styles.isRental]: isRental
          })}
          data-lbx-e2e={E2E_SELECTORS.RATING}
        >
          {`${rating} ${advisories}`}
        </span>
      </RatingsReasonsTooltip>
    )
  }

  const displayIsLeavingSoon = () => {
    /* LA30-2200: If comingSoon and leavingSoon = true, ignore leaving soon and only display coming soon */
    if (
      contentItem.isLeavingSoon &&
      !contentItem.isComingSoon &&
      contentItem.end
    ) {
      return (
        <LeavingSoonTitle
          endDate={contentItem.end}
          isRental={contentItem.isRental}
        />
      )
    }
    return null
  }

  // do not show auto trailer when user is not logged in
  const turnOffTrailerAutoplay =
    account?.settings?.turnOffTrailerAutoplay ?? true

  // Remove this FF and related code when improvement of line break in the description
  const manualDescriptionForTAYLORSWIFT = (useFeatureFlags(['manual_description_for_TAYLOR_SWIFT'])).manual_description_for_TAYLOR_SWIFT
  const contentDescription = manualDescriptionForTAYLORSWIFT && contentItem.id === '2d9e2d72-7d53-4342-9e57-3da066957325'
    ? `Experience TAYLOR SWIFT | THE ERAS TOUR (EXTENDED VERSION), including three songs from the tour not shown in theatres: "Long Live," "The Archer" and "Wildest Dreams." \nPlease note once purchased you will have 30 days to begin watching, and 48 hours to view. This title will expire at 12pm, 10th February. If this is within your 30 day rental period or 48 hour play window you no longer will be able to view.`
    : contentItem.description

  return (
    <>
      <div className={styles.titleDetail}>
        <HelmetIfNoModal>
          <meta property="og:title" content={title} />
          <meta property="og:type" content="video.movie" />
          <meta property="og:url" content={getLocationBaseWithHref()} />
          <meta property="og:video:release_date" content={contentItem.year} />
          <meta
            property="og:video:duration"
            content={contentItem.duration * 60}
          />
          {openGraphMetaImages(contentItem.images)}
          {openGraphMetaActors(contentItem.actors)}
          {openGraphMetaDirectors(contentItem.directors)}
          {openGraphMetaTags(contentItem.genres)}
          <meta name="description" property="og:description" content={title} />
          <link rel="canonical" href={getDetailsFullUrl(contentItem)} />
          <title>{title}</title>
        </HelmetIfNoModal>

        {!turnOffTrailerAutoplay && trailer ? (
          <AutoplayTrailerStream content={trailer} image={imageUri} />
        ) : (
          <HeroImage image={imageUri} title={contentItem.title} />
        )}

        {isRental && (
          <img
            src={rentalBadge}
            alt="Rental Content"
            className={styles.rentalBadge}
          />
        )}

        <div className={styles.header}>
          <div className={styles.leftHeader}>
            <h1
              className={classNames(styles.title, {
                [styles.isLongTitle]: isLongTitle
              })}
              data-lbx-e2e={E2E_SELECTORS.MOVIE_TITLE}
            >
              {contentItem.title}
            </h1>
            <div className={styles.ratingFrame}>
              {rating && isCVODRating && displayCVODRating()}
            </div>
            <div className={styles.subHeader}>
              {rating && !isCVODRating && displayBSVRating()}
              <div>
                {genres}
                {year ? (
                  <span data-lbx-e2e={E2E_SELECTORS.YEAR_RELEASED}>
                    {' '}
                    {year}
                    {' '}
                  </span>
                ) : null}
                {duration ? (
                  <span data-lbx-e2e={E2E_SELECTORS.DURATION_OF_MOVIE}>
                    {duration}
                  </span>
                ) : null}
              </div>
            </div>
            {contentItem.isComingSoon && contentItem.start ? (
              <UpcomingEpisode
                startDate={contentItem.start}
                isRental={contentItem.isRental}
                title
              />
            ) : null}

            { displayIsLeavingSoon() }

            {displayRentalInfo && (
              <RentalInfo rentalInfo={contentItem.rentalInfo} />
            )}

            <div className={styles.buttonsWrapper}>
              <WatchButton
                displayedInLocation={PLAY_BUTTON_IN_TITLE_HERO}
                contentItem={contentItem}
              />
              {trailer && (
                <ViewTrailerButton
                  trailer={trailer}
                  contentItem={contentItem}
                  id={trailer.id}
                  renderedInComponent={
                    isKids(theme)
                      ? COMPONENT_NAME_TITLE_DETAIL_KIDS
                      : COMPONENT_NAME_TITLE_DETAIL
                  }
                />
              )}
              <AddOrRemoveFromListButton
                contentItem={contentItem}
                myList={myList}
                renderedInComponent={
                  isKids(theme)
                    ? COMPONENT_NAME_TITLE_DETAIL_KIDS
                    : COMPONENT_NAME_TITLE_DETAIL
                }
                ctaAdd="MY LIST"
                ctaRemove="MY LIST"
                theme={theme}
              />
              {/*
                TODO: LBXW-605
                <ShareButton id={contentItem.id} />
              */}
            </div>
            {contentDescription && (
              <div className={styles.description}>
                {!showReadMoreButton || (showReadMoreButton && isExpanded) ? (
                  <Clamped lines={descriptionLines} className={styles.lineBreak}>
                    {contentDescription}
                  </Clamped>
                ) : (
                  <Clamped lines={3} className={styles.lineBreak}>{contentDescription}</Clamped>
                )}
              </div>
            )}
            {(!showReadMoreButton || (showReadMoreButton && isExpanded)) &&
              theme !== 'LIGHTBOX_KIDS' && (
              <CastCrew
                contentItem={contentItem}
                theme={theme}
                screenWidth={screenWidth}
              />
            )}
            {showReadMoreButton && (
              <div className={styles.linkWrapper}>
                <div
                  className={classNames(styles.link, {
                    [styles.isRental]: contentItem.isRental
                  })}
                  onClick={onReadMoreClick}
                >
                  <span className={styles.linkText}>{linkText}</span>
                  <ReactSVG
                    src={linkIcon}
                    beforeInjection={svg => {
                      svg.setAttribute('class', styles.linkIcon)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.rightHeader}>
            {theme !== 'LIGHTBOX_KIDS' && quote ? (
              <Quote text={quote.quote} author={quote.attribution} />
            ) : null}
          </div>
        </div>
      </div>
      <div
        className={classNames({ [styles.movieDisclaimer]: notes.length > 1 })}
      >
        {notes.length > 1 && (
          <ReactSVG
            src={infoIcon}
            beforeInjection={svg => {
              svg.setAttribute('class', styles.disclaimerIcon)
            }}
            className={styles.svgWrapper}
          />
        )}
        <div>
          {notes.length > 1 &&
            notes.map(note => {
              if (boldRegex.test(note)) {
                const match = boldRegex.exec(note)
                return (
                  <p>
                    <b>{match[1]}</b>
                  </p>
                )
              }

              return <p key={note}>{note}</p>
            })}
        </div>
      </div>
      <Reviews reviews={contentItem.ratingsAndAwards} isRental={isRental} />
    </>
  )
}

TitleDetail.propTypes = {
  contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired,
  account: PropTypes.shape({
    settings: PropTypes.shape({
      turnOffTrailerAutoplay: PropTypes.bool.isRequired
    })
  }).isRequired,
  myList: PropTypes.arrayOf(propType(CONTENT_ITEM_LIGHT_FRAGMENT)),
  theme: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  advisories: PropTypes.string.isRequired,
  notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  rentalStatus: PropTypes.string.isRequired,
  screenWidth: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onReadMoreClick: PropTypes.func
}

TitleDetail.defaultProps = {
  myList: [],
  onReadMoreClick: () => {}
}

export default TitleDetail
