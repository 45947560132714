import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import Input from './fields/input'
import PasswordInput from '../forms/password-input'
import SubmitButton from './fields/button'
import Checkbox from './fields/checkbox'
import NavLinkWithQuery from '../shared/nav-link-with-query'
import { getModalLocation } from '../../lib/modal'
import { MODALS } from '../../constants'

import styles from './login.css'
import { navigationGa } from '../../lib/analytics/ga'

import { E2E_SELECTORS } from '../../e2e-selectors'

function Login({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  error,
  touched,
  rememberMe,
  onRememberMeChange,
  isSubmitting,
  location,
  setFirstElement,
  loginBackgroundUri,
  isRental
}) {
  const urlSafeEmail = encodeURI(values.email || '')

  const passwordForgottenLocation = getModalLocation(
    location,
    MODALS.qsParams.passwordForgotten,
    { email: urlSafeEmail }
  )

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Login - NEON</title>
      </Helmet>

      <div className={styles.formWrapper}>
        <form
          className={classNames(styles.form, {
            [styles.isSubmitting]: isSubmitting
          })}
          onSubmit={handleSubmit}
        >
          <div className={styles.header}>
            <h1
              className={styles.title}
              data-lbx-e2e={E2E_SELECTORS.LOGIN_MODAL_HEADER}
            >
              Log in to Neon
            </h1>
          </div>

          <div
            className={styles.warning}
            data-lbx-e2e={E2E_SELECTORS.LOGIN_ERROR_TOP}
          >
            {error}
          </div>

          <div className={styles.formRow}>
            <div className={styles.formRowItem}>
              <Input
                label="Email address"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                warning={touched.email && errors.email}
                setRef={setFirstElement}
                e2eIdentifier="login-email"
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formRowItem}>
              <PasswordInput
                label="Password"
                name="password"
                value={values.password}
                min={6}
                max={21}
                onChange={handleChange}
                onBlur={handleBlur}
                warning={touched.password && errors.password}
                e2eIdentifier="login-password"
                darkTheme
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formRowItem}>
              <SubmitButton
                disabled={isSubmitting}
                e2eIdentifier="login-submit-button"
                text="Log in"
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formRowItem}>
              <Checkbox
                label="Remember me"
                isChecked={rememberMe}
                onChange={onRememberMeChange}
              />
              <span className={styles.modalLink}>
                Forgot your password?
                <NavLinkWithQuery
                  onClick={() => {
                    navigationGa({ action: 'Forgot password' })
                  }}
                  to={passwordForgottenLocation}
                  data-lbx-e2e={E2E_SELECTORS.RESET_PASSWORD_LINK}
                >
                  Reset your password here
                </NavLinkWithQuery>
              </span>
              <span className={styles.modalLink}>
                Don’t have a Neon account?
                <NavLinkWithQuery
                  onClick={() => {
                    navigationGa({ action: 'Go to sign up page - via login' })
                  }}
                  to={{ pathname: '/signup' }}
                >
                  Sign up here
                </NavLinkWithQuery>
              </span>
            </div>
          </div>
        </form>

        {!location.pathname.startsWith('/movie') && (
          <div className={styles.imageWrapper}>
            <div
              className={styles.loginImage}
              style={{ backgroundImage: `url(${loginBackgroundUri}?fit=crop&h=420&w=280)` }}
              alt="Watch"
            />
          </div>
        )}
      </div>
    </div>
  )
}

Login.propTypes = {
  loginBackgroundUri: PropTypes.string.isRequired,
  rememberMe: PropTypes.bool.isRequired,
  onRememberMeChange: PropTypes.func.isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFirstElement: PropTypes.func.isRequired,
  isRental: PropTypes.bool
}

Login.defaultProps = {
  error: '',
  isRental: false
}

export default Login
